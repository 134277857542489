import React, { Component, useEffect, useState } from 'react'
import { handleImageSrc } from './util'
import './Root.css'

import { useNotification } from './NotificationProvider'

import avatar from '../assets/avatar.png'
import logo from '../assets/Liquid-Avatar-Tech-inc-®-Logo-Rev-FINAL.png'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import { parseInteger } from 'luxon/src/impl/util'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 10px;
  width: 300px;
`
const IdentityImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  border-radius: 10px;
`

function OldVerifierRoot(props) {
  const setNotification = useNotification()
  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForPhone, setWaitingForPhone] = useState(false)
  const [waitingForEmail, setWaitingForEmail] = useState(false)
  const [waitingForDriversLicense, setWaitingForDriversLicense] = useState(
    false
  )
  const [waitingForPassport, setWaitingForPassport] = useState(false)
  const [waitingForVaccination, setWaitingForVaccination] = useState(false)
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    if (!waitingForInvitation) {
      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol:
          'did:sov:BzCbsNYhMrjHiqZDTUASHg;spec/didexchange/1.0',
        alias: 'Verifier',
        invitationMode: 'once',
        accept: 'auto',
        public: true,
        invitationDescription: 'Public OOB Invitation',
      })
    }
  }, [waitingForInvitation])

  useEffect(() => {
    if (props.connectionReuse) {
      const message = `Connection reused for ${props.connectionReuse.connection_id}`
      setNotification(message, 'notice')
    }
  }, [props.connectionReuse])

  useEffect(() => {
    if (props.QRCodeURL !== '') {
      setWaitingForConnection(true)
    }

    if (
      props.contacts.length > 0 &&
      props.contacts[0].Connections != undefined &&
      props.contacts[0].Connections != null
    ) {
      if (
        props.contacts[0].Connections.length > 0 &&
        waitingForConnection &&
        !connected
      ) {
        if (
          props.contacts[0].Connections[0].state === 'active' ||
          props.contacts[0].Connections[0].state === 'completed'
        ) {
          setWaitingForConnection(false)
          setConnected(true)
        }
      }
    }
  }, [props.QRCodeURL, props.contacts, props.sendRequest, waitingForConnection])

  const reloadPage = () => {
    window.location.reload()
    return false
  }

  const clearRequests = () => {
    const { setVerifiedCredential, setVerificationStatus } = props

    setWaitingForPhone(false)
    setWaitingForEmail(false)
    setWaitingForDriversLicense(false)
    setWaitingForPassport(false)
    setWaitingForVaccination(false)

    setVerifiedCredential(undefined)
    setVerificationStatus(undefined)
  }

  const requestPhone = () => {
    clearRequests()
    setWaitingForPhone(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_LEVEL_1_PHONE,
      attributes: ['phone', 'identity_image', 'date_validated'],
    })
  }

  const requestEmail = () => {
    clearRequests()
    setWaitingForEmail(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_LEVEL_1_EMAIL,
      attributes: ['address', 'identity_image', 'date_validated'],
    })
  }

  const requestDriversLicense = () => {
    clearRequests()
    setWaitingForDriversLicense(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_DRIVERS_LICENSE,
      attributes: ['given_names', 'surname', 'date_of_expiration'],
    })
  }

  const requestPassport = () => {
    clearRequests()
    setWaitingForPassport(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_PASSPORT,
      attributes: ['given_names', 'surname', 'date_of_expiration'],
    })
  }

  const requestVaccination = () => {
    clearRequests()
    setWaitingForVaccination(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_VACCINATION,
      attributes: [
        'patient_given_names',
        'patient_surnames',
        'vaccine_administration_date',
        'vaccine_disease_target_code',
      ],
    })
  }

  const displayPhone = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPhone) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Phone Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Phone Result Verified!</h3>
              <p className="para">
                Phone: {props.verifiedCredential.phone.raw}
              </p>
              <IdentityImage
                src={props.verifiedCredential['identity_image'].raw}
              />
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Phone Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">Phone Result Verification Failed!</h3>
            <p className="para">
              There was a problem verifying your credential. Please try again or
              contact support.
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayEmail = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForEmail) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Email Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Email Result Verified!</h3>
              <p className="para">
                Email: {props.verifiedCredential.address.raw}
              </p>
              <IdentityImage
                src={props.verifiedCredential['identity_image'].raw}
              />
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Email Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">Email Result Verification Failed!</h3>
            <p className="para">
              There was a problem verifying your credential. Please try again or
              contact support.
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayDriversLicense = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForDriversLicense) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Drivers License Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          let credentialDate = new Date(
            parseInteger(
              props.verifiedCredential.date_of_expiration.raw.toString()
            ) * 1000
          )
          let formattedDate =
            credentialDate.getMonth() +
            1 +
            '/' +
            credentialDate.getDate() +
            '/' +
            credentialDate.getFullYear()
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Drivers License Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.given_names.raw}{' '}
                {props.verifiedCredential.surname.raw}
              </p>
              <p className="para">Expiration: {formattedDate}</p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Drivers License Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">
              Drivers License Result Verification Failed!
            </h3>
            <p className="para">
              There was a problem verifying your credential. Please try again or
              contact support.
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayPassport = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPassport) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Passport Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          let credentialDate = new Date(
            parseInteger(
              props.verifiedCredential.date_of_expiration.raw.toString()
            ) * 1000
          )
          let formattedDate =
            credentialDate.getMonth() +
            1 +
            '/' +
            credentialDate.getDate() +
            '/' +
            credentialDate.getFullYear()
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Passport Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.given_names.raw}{' '}
                {props.verifiedCredential.surname.raw}
              </p>
              <p className="para">Expiration: {formattedDate}</p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Passport Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">Passport Result Verification Failed!</h3>
            <p className="para">
              There was a problem verifying your credential. Please try again or
              contact support.
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayVaccination = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForVaccination) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Vaccination Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Vaccination Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.patient_given_names.raw}{' '}
                {props.verifiedCredential.patient_surnames.raw}
              </p>
              <p className="para">
                Administration Date:{' '}
                {props.verifiedCredential.vaccine_administration_date.raw}
              </p>
              <p className="para">
                Vaccine Target:{' '}
                {props.verifiedCredential.vaccine_disease_target_code.raw}
              </p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Vaccination Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">
              Vaccination Result Verification Failed!
            </h3>
            <p className="para">
              There was a problem verifying your credential. Please try again or
              contact support.
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayCredentialData = () => {
    let phoneResult = displayPhone()
    let emailResult = displayEmail()
    let driversLicenseResult = displayDriversLicense()
    let passportResult = displayPassport()
    let vaccinationResult = displayVaccination()

    return (
      <div className="right-fold landing-col-6">
        <h1 className="header">Verify Your Credentials</h1>
        <div className="credentials-align">
          <p>Select your credential type</p>

          <button onClick={requestPhone}>Phone</button>

          <button onClick={requestEmail}>Email</button>

          <button onClick={requestDriversLicense}>Drivers License</button>

          <button onClick={requestPassport}>Passport</button>

          <button onClick={requestVaccination}>Vaccination</button>

          {phoneResult}
          {emailResult}
          {driversLicenseResult}
          {passportResult}
          {vaccinationResult}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="landing-container-fluid">
        <div className="landing-row">
          <div className="home landing-col s12">
            <div className="landing-col upper-fold">
              <button className="btn-home" onClick={reloadPage}>
                <img src={logo} className="img-fluid" alt="" />
              </button>
              <div className="landing-container">
                <div className="landing-row">
                  <div className="avatar-container left-fold landing-col-6">
                    <img src={avatar} className="avatar" alt="" />
                  </div>
                  {connected ? (
                    displayCredentialData()
                  ) : (
                    <div className="right-fold landing-col-6">
                      <h1 className="header">Verify Your Credentials</h1>
                      <p className="para">
                        Simply scan the following QR code to begin the
                        verification process:
                      </p>
                      {props.QRCodeURL ? (
                        <div className="qr">
                          <p>
                            <QR
                              value={props.QRCodeURL}
                              size={256}
                              renderAs="svg"
                            />
                          </p>
                        </div>
                      ) : (
                        <p>
                          <span>Loading...</span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OldVerifierRoot
