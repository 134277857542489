import React, { Component, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'

import { handleImageSrc } from './util'
import './Root.css'
import { DateTime } from 'luxon'
import { useNotification } from './NotificationProvider'

import liquidLogo from '../assets/Liquid-Avatar-Tech-inc-®-Logo-Rev-FINAL.png'
import homepageRestrictedContent from '../assets/Spellcaster-tall-v1.png'
import homepageAftermathIslands from '../assets/Armor-tall-v1.png'
import homepageRestaurantReservation from '../assets/Waitress-tall-v1.png'
import homepageCrypto from '../assets/Dude-tall-v1.png'
import restrictedContentFirstVideo from '../assets/restricted_content_part_1.mp4'
import restrictedContentSecondVideo from '../assets/restricted_content_part_2.mp4'
import restrictedContentStillImage from '../assets/restricted_content_still_image.jpg'
import aftermathIslandsFirstVideo from '../assets/aftermath_islands_part_1.mp4'
import aftermathIslandsSecondVideo from '../assets/aftermath_islands_part_2.mp4'
import aftermathIslandsStillImage from '../assets/aftermath_islands_still_image.jpg'
import restaurantReservationFirstVideo from '../assets/restaurant_reservation_part_1.mp4'
import restaurantReservationSecondVideo from '../assets/restaurant_reservation_part_2.mp4'
import restaurantReservationStillImage from '../assets/restaurant_reservation_still_image.jpg'
import cryptoFirstVideo from '../assets/crypto_part_1.mp4'
import cryptoSecondVideo from '../assets/crypto_part_2.mp4'
import cryptoStillImage from '../assets/crypto_still_image.jpg'
import aftermathBackground from '../assets/aftermath-background.png'
import cryptoComBackground from '../assets/crypto-com.png'
import restrictedContentBackground from '../assets/restricted-content-background.png'
import restaurantBackground from '../assets/restaurant-background.png'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import { parseInteger } from 'luxon/src/impl/util'
import { Fireworks } from 'fireworks-js'
import parsePhoneNumber from 'libphonenumber-js'
import OldVerifierRoot from './OldVerifierRoot'

// Import environment variables for use via an .env file in a non-containerized context
const dotenv = require('dotenv')
dotenv.config()

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 10px;
  width: 300px;
`
const IdentityImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  border-radius: 10px;
`

function Root(props) {
  const setNotification = useNotification()

  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForPhone, setWaitingForPhone] = useState(false)
  const [waitingForEmail, setWaitingForEmail] = useState(false)
  const [waitingForParkPass, setWaitingForParkPass] = useState(false)
  const [waitingForDriversLicense, setWaitingForDriversLicense] = useState(
    false
  )
  const [waitingForPassport, setWaitingForPassport] = useState(false)
  const [waitingForVaccination, setWaitingForVaccination] = useState(false)
  const [waitingForQuestion, setWaitingForQuestion] = useState(false)
  const [connected, setConnected] = useState(false)
  const [ageValidation, setAgeValidation] = useState(false)
  const [verifier, setVerifier] = useState(false)
  const [isHomeScreen, setIsHomeScreen] = useState(true)
  const [aftermathLogin, setAftermathLogin] = useState(false)
  const [cryptoLogin, setCryptoLogin] = useState(false)
  const [cryptoStepOne, setCryptoStepOne] = useState(false)
  const [cryptoStepTwo, setCryptoStepTwo] = useState(false)
  const [restrictedContent, setRestrictedContent] = useState(false)
  const [restaurantReservation, setRestaurantReservation] = useState(false)
  const [covidVaccinationPage, setCovidVaccinationPage] = useState(false)
  const [hasVaccination, setHasVaccination] = useState(false)
  const [rejectVaccination, setRejectVaccination] = useState(false)
  const [isAftermathLogin, setIsAftermathLogin] = useState(false)
  const [existingAccount, setExistingAccount] = useState(false)
  const [isAftermathSignup, setIsAftermathSignup] = useState(false)
  const [isAftermathSignupComplete, setIsAftermathSignupComplete] = useState(
    false
  )
  const [requestedAllCredentials, setRequestedAllCredentials] = useState(false)
  const [videoCompleted, setVideoCompleted] = useState(false)
  const [processComplete, setProcessComplete] = useState(false)
  const [rejectedAge, setRejectedAge] = useState(false)
  const [sex, setSex] = useState('')
  const [firstName, setFirstName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [lastName, setLastName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [country, setCountry] = useState('')
  const [aftermathFormDone, setAftermathFormDone] = useState(false)
  const [parkPassResponse, setParkPassResponse] = useState(false)
  const [missingAttributes, setMissingAttributes] = useState(false)
  const [invitationUrl, setInvitationUrl] = useState(false)
  const [credentialVerified, setCredentialVerified] = useState(false)
  const [verificationResult, setVerificationResult] = useState('')

  let hasAftermathAccount = false
  let requestSent = false
  let invitationId = ''
  let rawInvitationUrl = ''
  let verificationId = ''
  let aftermathLoginUseCase = 'aftermathLogin'
  let restaurantReservationUseCase = 'restaurantReservation'
  let useCase = ''

  useEffect(() => {
    if (
      !waitingForInvitation &&
      props.anonWebsocket &&
      props.readyForAnonMessages
    ) {
      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol:
          'did:sov:BzCbsNYhMrjHiqZDTUASHg;spec/didexchange/1.0',
        alias: 'Verifier',
        invitationMode: 'once',
        accept: 'auto',
        public: true,
        invitationDescription: 'Public OOB Invitation',
      })
      setWaitingForInvitation(true)
    }
  }, [props.anonWebsocket, props.readyForAnonMessages])

  useEffect(() => {
    if (props.connectionReuse) {
      const message = `Connection reused for ${props.connectionReuse.connection_id}`
      setNotification(message, 'notice')
    }
  }, [props.connectionReuse])

  useEffect(() => {
    if (props.QRCodeURL !== '') {
      setWaitingForConnection(true)
    }
    if (
      props.contacts.length > 0 &&
      props.contacts[0].Connections != undefined &&
      props.contacts[0].Connections != null
    ) {
      if (
        props.contacts[0].Connections.length > 0 &&
        waitingForConnection &&
        !connected
      ) {
        if (
          props.contacts[0].Connections[0].state === 'active' ||
          props.contacts[0].Connections[0].state === 'completed'
        ) {
          setWaitingForConnection(false)
          setConnected(true)
          requestCredential()
        }
      }
    }

    if (props.questionAnswer !== undefined) {
      if (props.questionAnswer) {
        if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('passport') &&
          waitingForQuestion
        ) {
          requestPassport()
          console.log('GOT ANSWER')
        } else if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('drivers') &&
          waitingForQuestion
        ) {
          requestDriversLicense()
        } else if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('parkpasspresent')
        ) {
          console.log('parkPassPresent')
          setParkPassResponse(true)
        } else if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('parkpassnotpresent')
        ) {
          setParkPassResponse(false)
        }
      }
    }
  }, [
    props.QRCodeURL,
    props.contacts,
    props.questionAnswer,
    waitingForConnection,
  ])

  useEffect(() => {
    const fetchData = async () => {
      await createInvitation()
      if (window.location.href.toString().includes('verification_id')) {
        verificationId = window.location.href
          .split('?verification_id=')[1]
          .split('&')[0]
        useCase = window.location.href.split('&use_case=')[1]

        await readInvitation()
      } else {
        if (isAftermathLogin) {
          await createVerification('park_pass')
        } else {
          await createVerification('phone')
        }

        setInvitationUrl(rawInvitationUrl)
        await pollVerification()
      }
    }

    if (props.schemas && props.schemas.LIQUID_AVATAR_API) {
      fetchData().catch(console.error)
    }
  }, [props.schemas])

  const clearRequests = () => {
    const {
      setVerifiedCredential,
      setVerificationStatus,
      setQuestionAnswer,
    } = props

    setWaitingForPhone(false)
    setWaitingForEmail(false)
    setWaitingForDriversLicense(false)
    setWaitingForPassport(false)
    setWaitingForVaccination(false)
    setWaitingForQuestion(false)

    setVerifiedCredential(undefined)
    setVerificationStatus(undefined)
    setQuestionAnswer(undefined)
  }

  const reloadPage = () => {
    window.location.reload()
    return false
  }

  const requestCredential = () => {
    aftermathLogin && isAftermathLogin
      ? requestParkPass()
      : aftermathLogin && isAftermathSignup
      ? requestPhone()
      : restrictedContent || ageValidation
      ? requestQuestionAgeVerification()
      : restaurantReservation
      ? requestPhone()
      : cryptoLogin
      ? requestQuestionKYC()
      : noRequest()
  }

  const noRequest = () => {}

  const requestPhone = () => {
    clearRequests()
    setWaitingForPhone(true)
    setWaitingForQuestion(false)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_LEVEL_1_PHONE,
      attributes: ['phone', 'identity_image', 'date_validated'],
    })

    if (isAftermathSignup) {
      requestQuestionParkPass()
    }
  }

  const requestParkPass = () => {
    clearRequests()
    setWaitingForParkPass(true)
    console.log('Requesting park pass')
    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_PARK_PASS,
      attributes: ['phone_number', 'country', 'date_of_birth'],
    })
  }

  const requestEmail = () => {
    clearRequests()
    setWaitingForEmail(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_LEVEL_1_EMAIL,
      attributes: ['address', 'date_validated'],
    })
  }

  const requestDriversLicense = () => {
    clearRequests()
    setWaitingForQuestion(false)
    setWaitingForDriversLicense(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_DRIVERS_LICENSE,
      attributes: [
        'given_names',
        'surname',
        'date_of_expiration',
        'date_of_birth',
        'sex',
      ],
    })
  }

  const requestPassport = () => {
    clearRequests()
    console.log(waitingForQuestion)
    setWaitingForQuestion(false)
    setWaitingForPassport(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_PASSPORT,
      attributes: [
        'given_names',
        'surname',
        'date_of_expiration',
        'date_of_birth',
        'issuing_country',
        'sex',
      ],
    })
  }

  const requestVaccination = () => {
    clearRequests()
    setWaitingForVaccination(true)

    props.sendRequest('PRESENTATIONS', 'REQUEST', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      schema_id: props.schemas.SCHEMA_VACCINATION,
      attributes: [
        'patient_given_names',
        'patient_surnames',
        'vaccine_administration_date',
        'vaccine_series_complete',
      ],
    })
  }

  const requestQuestionAgeVerification = () => {
    clearRequests()
    setWaitingForQuestion(true)
    setWaitingForPassport(false)
    setWaitingForDriversLicense(false)

    props.sendRequest('QUESTIONS', 'ASK', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      question_text: 'What document do you want to use for age verification?',
      question_detail: 'Please choose one option below.',
      valid_responses: [{ text: 'passport' }, { text: 'drivers license' }],
    })
  }

  const requestQuestionParkPass = () => {
    props.sendRequest('QUESTIONS', 'ASK', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      question_text: 'Do you have a parkPass credential?',
      question_detail: 'Please choose one option below.',
      valid_responses: [
        { text: 'parkPassPresent' },
        { text: 'parkPassNotPresent' },
      ],
    })
  }

  const requestQuestionAftermathSignup = () => {
    clearRequests()
    setWaitingForQuestion(true)
    setWaitingForPassport(false)
    setWaitingForDriversLicense(false)

    props.sendRequest('QUESTIONS', 'ASK', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      question_text: 'What document do you want to use for aftermath signup?',
      question_detail: 'Please choose one option below.',
      valid_responses: [{ text: 'passport' }, { text: 'drivers license' }],
    })
  }

  const requestQuestionKYC = () => {
    clearRequests()
    setWaitingForQuestion(true)
    setWaitingForPassport(false)
    setWaitingForDriversLicense(false)

    props.sendRequest('QUESTIONS', 'ASK', {
      connection_id: props.contacts[0].Connections[0].connection_id,
      question_text: 'What document do you want to use for KYC verification?',
      question_detail: 'Please choose one option below.',
      valid_responses: [{ text: 'passport' }, { text: 'drivers license' }],
    })
  }

  const createInvitation = async () => {
    await axios
      .post(props.schemas.LIQUID_AVATAR_API + '/api/enterprise/invitations', {})
      .then(async function (response) {
        console.log('Created invitation')
        console.log(response)
        invitationId = response.data.data.invitation_id
        rawInvitationUrl = response.data.data.invitation_url
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const createVerification = async (schemaName) => {
    await axios
      .post(props.schemas.LIQUID_AVATAR_API + '/api/enterprise/verifications', {
        schema_name: schemaName,
        invitation_id: invitationId,
      })
      .then(function (response) {
        console.log('Created verification')
        console.log(response)
        verificationId = response.data.data.verification_id
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const readInvitation = async () => {
    let result = await axios.get(
      props.schemas.LIQUID_AVATAR_API +
        '/api/enterprise/verifications/' +
        verificationId
    )
    if (result.status === 200) {
      let responseData = result.data['data']
      console.log('Verification status:')
      console.log(responseData)

      if (responseData.complete === true) {
        setVerificationResult(responseData)
        setCredentialVerified(true)

        if (responseData.result === true) {
          if (useCase === aftermathLoginUseCase) {
            setAftermathLogin(true)
          } else if (useCase === restaurantReservationUseCase) {
            setRestaurantReservation(true)
          }
        }
      }
    }
  }

  const pollVerification = () => {
    setInterval(async () => {
      let result = await axios.get(
        props.schemas.LIQUID_AVATAR_API +
          '/api/enterprise/verifications/' +
          verificationId
      )
      if (result.status === 200) {
        let responseData = result.data['data']
        // console.log('Verification status:')
        // console.log(responseData)

        if (responseData.complete === true) {
          setVerificationResult(responseData)
          setCredentialVerified(true)
        }
      }
    }, 2000)
  }

  const displayPhone = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPhone) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Phone Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Phone Result Verified!</h3>
              <p className="para">
                Phone: {props.verifiedCredential.phone.raw}
              </p>
              <IdentityImage
                src={props.verifiedCredential['identity_image'].raw}
              />
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Phone Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <p className="para">
              Credential verification failed! {verifiedCredential}
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayEmail = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForEmail) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Email Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Email Result Verified!</h3>
              <p className="para">
                Email: {props.verifiedCredential.address.raw}
              </p>
              <IdentityImage
                src={props.verifiedCredential['identity_image'].raw}
              />
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Email Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <p className="para">
              Credential verification failed! {verifiedCredential}
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayDriversLicense = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForDriversLicense) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Drivers License Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Drivers License Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.given_names.raw}{' '}
                {props.verifiedCredential.surname.raw}
              </p>
              <p className="para">
                Expiration: {props.verifiedCredential.date_of_expiration.raw}
              </p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Drivers License Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <p className="para">
              Credential verification failed! {verifiedCredential}
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayPassport = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPassport) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Passport Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Passport Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.given_names.raw}{' '}
                {props.verifiedCredential.surname.raw}
              </p>
              <p className="para">
                Expiration: {props.verifiedCredential.date_of_expiration.raw}
              </p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Passport Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <p className="para">
              Credential verification failed! {verifiedCredential}
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayVaccination = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForVaccination) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Vaccination Result!</h3>
        <p className="para">
          You will now receive a request on your mobile app to send your
          credential to us for verification.
        </p>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Vaccination Result Verified!</h3>
              <p className="para">
                Name: {props.verifiedCredential.patient_given_names.raw}{' '}
                {props.verifiedCredential.patient_surnames.raw}
              </p>
              <p className="para">
                Administration Date:{' '}
                {props.verifiedCredential.vaccine_administration_date.raw}
              </p>
              <p className="para">
                Vaccine Target:{' '}
                {props.verifiedCredential.vaccine_disease_target_code.raw}
              </p>
            </div>
          )
        } else {
          result = (
            <div className="credential-main">
              <h3 className="sub-header">Vaccination Result Verified!</h3>
              <p className="para">No Credential Data Was Passed!</p>
            </div>
          )
        }
      } else {
        result = (
          <div className="credential-main">
            <p className="para">
              Credential verification failed! {verifiedCredential}
            </p>
          </div>
        )
      }
    }

    return result
  }

  const displayQuestion = () => {
    const { questionAnswer } = props

    if (!waitingForQuestion) {
      return ''
    }

    let result = (
      <div className="credential-main">
        <h3 className="sub-header">Question?</h3>
        <p className="para">Waiting for document type...</p>
      </div>
    )

    if (questionAnswer !== undefined) {
      if (questionAnswer) {
        result = (
          <div className="credential-main">
            <h3 className="sub-header">Question Result!</h3>
            <p className="para">{props.questionAnswer.question_text}</p>
            <p className="para">{props.questionAnswer.response}</p>
          </div>
        )
      }
    }

    return result
  }

  const displayLoaderPhoneCredential = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPhone) {
      return ''
    }

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Waiting for credential...</h1>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">Level 1 credential received</h1>
              <h1 className="header">
                Credential verified! You are now logged in!
              </h1>
            </div>
          )
        } else {
          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">No credential data was passed.</h1>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    return result
  }

  const displayWelcomeScreen = () => {
    const aftermathButtonStyle = {
      backgroundImage: 'url(' + aftermathIslandsStillImage + ')',
    }

    const ageRestrictedButtonStyle = {
      backgroundImage: 'url(' + restrictedContentStillImage + ')',
    }

    const restaurantReservationButtonStyle = {
      backgroundImage: 'url(' + restaurantReservationStillImage + ')',
    }

    const cryptoButtonStyle = {
      backgroundImage: 'url(' + cryptoStillImage + ')',
    }

    return (
      <div className="homepage">
        <div className="homepage-element">
          <img
            src={homepageAftermathIslands}
            className="img-home"
            onClick={clickAftermath}
            alt=""
          />
          <h1 className="description">Metaverse ParkPass</h1>
          <p className="sub-description">
            Incorporate a universal technology to log into your Web3 metaverse
            project.
          </p>
          <p className="sub-description">
            This method uses your Phone Verifiable Credential and your
            Biometrics to prove you are a real person.{' '}
          </p>
        </div>
        <div className="homepage-element-foreground">
          <img
            src={homepageCrypto}
            className="img-home"
            onClick={clickCrypto}
            alt=""
          />
          <h1 className="description">
            Know Your Customer <br />
            (KYC) registration
          </h1>
          <p className="sub-description">
            Be compliant with your web, service or membership userbase.{' '}
          </p>
          <p className="sub-description">
            This method requires a driver's license or passport verifiable
            credential to register for an account.{' '}
          </p>
        </div>
        <div className="homepage-element-foreground">
          <img
            src={homepageRestaurantReservation}
            onClick={clickRestaurantReservation}
            className="img-home"
            alt=""
          />
          <h1 className="description">Reservations</h1>
          <p className="sub-description">
            Quickly get the information you require for your reservation system.
          </p>
          <p className="sub-description">
            This method uses your Phone Verifiable Credential.
          </p>
        </div>
        <div className="homepage-element">
          <img
            src={homepageRestrictedContent}
            onClick={clickRestrictedContent}
            className="img-home"
            alt=""
          />
          <h1 className="description">Gaming</h1>
          <p className="sub-description">Age verification for your games.</p>
          <p className="sub-description">
            Use your driver's license or passport verifiable credential to prove
            you are over 18 to enter a website.
          </p>
        </div>
      </div>
    )
  }

  const clickAgeValidation = () => {
    setAgeValidation(true)
    setIsHomeScreen(false)
  }

  const clickVerifier = () => {
    setVerifier(true)
    setIsHomeScreen(false)
  }

  const clickAftermath = () => {
    useCase = aftermathLoginUseCase
    setAftermathLogin(true)
    setIsHomeScreen(false)
  }

  const clickCrypto = () => {
    setCryptoLogin(true)
    setIsHomeScreen(false)
  }

  const clickCryptoStepOne = () => {
    setCryptoStepOne(true)
  }

  const clickCryptoStepTwo = () => {
    setCryptoStepOne(false)
    setCryptoStepTwo(true)
  }

  const clickRestrictedContent = () => {
    setRestrictedContent(true)
    setIsHomeScreen(false)
  }

  const clickReserveTable = () => {
    setCovidVaccinationPage(true)
    setIsHomeScreen(false)
  }

  const clickYesVaccine = () => {
    setHasVaccination(true)
    setCovidVaccinationPage(false)
  }

  const clickNoVaccine = () => {
    setHasVaccination(false)
    setRejectVaccination(true)
  }

  const clickAftermathLogin = () => {
    setIsAftermathSignup(false)
    setIsAftermathSignupComplete(false)
    hasAftermathAccount = false
    setIsAftermathLogin(true)
  }

  const clickAftermathSignup = () => {
    setIsAftermathLogin(false)
    setIsAftermathSignupComplete(false)
    hasAftermathAccount = false
    setIsAftermathSignup(true)
  }

  const validateForm = () => {
    let phoneNumber = document.forms['myForm']['phone-number'].value
    let country = document.forms['myForm']['country'].value
    let dateOfBirth = document.forms['myForm']['date-of-birth'].value

    if (phoneNumber === '' || country === '' || dateOfBirth === '') {
      alert('Please fill in all fields')
      return false
    }

    return true
  }

  const clickAftermathSignupComplete = () => {
    const { credentials, credentialsLoaded } = props

    let validForm = validateForm()

    if (parkPassResponse) {
      console.log('Account exists!')
      hasAftermathAccount = true
      setExistingAccount(true)
      setIsAftermathSignupComplete(false)
      setIsAftermathSignup(false)
      setIsAftermathLogin(false)
      setConnected(false)
    } else {
      hasAftermathAccount = false
    }

    if (validForm && !hasAftermathAccount) {
      setIsAftermathSignupComplete(true)
      setIsAftermathSignup(false)
      setIsAftermathLogin(false)

      let dob = dateOfBirth.toLocaleDateString('en-US')

      props.sendRequest('QUESTIONS', 'ASK', {
        connection_id: props.contacts[0].Connections[0].connection_id,
        question_text: 'Park Pass',
        question_detail: `{"phone_number":"${phoneNumber}", "country_code":"${country}", "date_of_birth":"${dob}"}`,
        valid_responses: [],
      })
    }
  }

  const clickRestaurantReservation = () => {
    useCase = restaurantReservationUseCase
    setRestaurantReservation(true)
    setIsHomeScreen(false)
  }

  const updateAftermathHomepage = () => {
    document.getElementById(
      'home'
    ).style.backgroundImage = `url(${aftermathBackground})`
  }

  const updateCryptoHomepage = () => {
    document.getElementById(
      'home'
    ).style.backgroundImage = `url(${cryptoComBackground})`
  }

  const updateRestrictedContentHomepage = () => {
    document.getElementById(
      'home'
    ).style.backgroundImage = `url(${restrictedContentBackground})`
  }

  const updateRestaurantReservationHomepage = () => {
    document.getElementById(
      'home'
    ).style.backgroundImage = `url(${restaurantBackground})`
  }

  const displayCryptoLogin = () => {
    const { verifiedCredential, verificationStatus } = props

    let result

    if (!cryptoStepOne && !cryptoStepTwo) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Welcome to Crypto.com</h1>
          <button className="btn" onClick={clickCryptoStepOne}>
            Register
          </button>
        </div>
      )
    } else if (cryptoStepOne) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Please fill in the required fields</h1>
          <div className="row">
            <div className="column">
              <form className="form">
                <label className="label" htmlFor="username">
                  Email:
                </label>
                <input type="text" id="username" name="username"></input>
                <label className="label" htmlFor="pwd">
                  Password:
                </label>
                <input type="password" id="pwd" name="pwd"></input>
                <label className="label" htmlFor="pwd">
                  Confirm password:
                </label>
                <input
                  type="password"
                  id="pwd-confirmation"
                  name="pwd-confirmation"
                ></input>
                <label className="label" htmlFor="country">
                  Country
                </label>

                <select id="country" name="country" className="form-control">
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Romania">Romania</option>
                </select>
              </form>
            </div>
          </div>
          <button className="btn" onClick={clickCryptoStepTwo}>
            Register
          </button>
        </div>
      )
    } else if (cryptoStepTwo) {
      function onVideoEnd() {
        setVideoCompleted(true)
      }

      result = (
        <div>
          <div className="right-fold landing-col-6">
            <video
              id="cryptoVideo"
              autoPlay="autoplay"
              muted
              onEnded={onVideoEnd}
            >
              <source src={cryptoFirstVideo} type="video/mp4"></source>
            </video>
          </div>

          {videoCompleted &&
          !waitingForQuestion &&
          !waitingForPassport &&
          !waitingForDriversLicense ? (
            <div className="stack-top">
              <h1 className="header-top-crypto">
                Please Scan the QR code below using LiquidAvatar app to provide
                KYC credential
              </h1>

              {props.QRCodeURL && !waitingForQuestion ? (
                <div className="qr-big-crypto">
                  <p>
                    <QR value={props.QRCodeURL} size={256} renderAs="svg" />
                  </p>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          ) : videoCompleted && waitingForQuestion ? (
            <h1 className="header-top-crypto">Waiting for document type...</h1>
          ) : videoCompleted &&
            (waitingForPassport || waitingForDriversLicense) ? (
            <h1 className="header-top-crypto">Waiting for credential...</h1>
          ) : (
            <p></p>
          )}
        </div>
      )
    }

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential && !processComplete) {
          try {
            let age = props.verifiedCredential.date_of_birth.raw
          } catch (exception) {
            if (!missingAttributes) {
              setMissingAttributes(true)
            }
            console.log('Missing attributes')
          }

          result = (
            <div className="right-fold landing-col-6">
              <video id="cryptoVideo" autoPlay="autoplay" muted>
                <source src={cryptoSecondVideo} type="video/mp4"></source>
              </video>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    if (missingAttributes) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      )
    }

    return result
  }

  function changeSex(val) {
    document.getElementById('sex').value = val
  }

  const displayAftermathLogin = () => {
    const { verifiedCredential, verificationStatus, credentialsLoaded } = props

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Welcome to Aftermath islands</h1>
        <div className="credentials-align"></div>

        <button className="btn" onClick={clickAftermathLogin}>
          Login
        </button>
        <button className="btn" onClick={clickAftermathSignup}>
          Signup
        </button>
      </div>
    )

    if (existingAccount) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Sorry, you cannot signup</h1>
          <h1 className="header">You already have an account</h1>
        </div>
      )
    } else if (isAftermathLogin) {
      function onVideoEnd() {
        setVideoCompleted(true)
      }

      result = missingAttributes ? (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      ) : (
        <div>
          <div className="right-fold landing-col-6">
            <video
              id="restrictedContentVideo"
              autoPlay="autoplay"
              muted
              onEnded={onVideoEnd}
            >
              <source
                src={aftermathIslandsFirstVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>

          {videoCompleted && !waitingForParkPass ? (
            <div className="right-fold-top-single landing-col-6-top-single">
              <h1 className="header-2">
                Please Scan the QR code below using LiquidAvatar app in order to
                login
              </h1>

              <div className="credentials-align"></div>

              {invitationUrl && !waitingForParkPass ? (
                <div>
                  <div className="qr">
                    <p>
                      <QR value={invitationUrl} size={512} renderAs="svg" />
                    </p>
                  </div>
                  <h1 className="header-2">
                    Or{' '}
                    <a
                      href={
                        'https://kabnnetwork.page.link/?efr=0&ibi=com.kabnnetwork.liquidavatar&apn=com.kabnnetwork.liquidavatar&link=' +
                        encodeURIComponent(
                          'https://liquidavatar.com/?c_i=' +
                            invitationUrl.split('c_i=')[1] +
                            '&redirect=' +
                            encodeURIComponent(
                              window.location.href.toString() +
                                '?verification_id=' +
                                verificationId +
                                '&use_case=' +
                                useCase
                            )
                        )
                      }
                    >
                      click this link to provide a credential
                    </a>
                  </h1>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          ) : videoCompleted && waitingForParkPass ? (
            <div className="right-fold-top landing-col-6-top">
              <h1 className="header-2">Waiting for credential...</h1>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      )
    } else if (isAftermathSignup) {
      function onVideoEnd() {
        setVideoCompleted(true)
      }

      result = missingAttributes ? (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      ) : (
        <div>
          <div className="right-fold landing-col-6">
            <video
              id="restrictedContentVideo"
              autoPlay="autoplay"
              muted
              onEnded={onVideoEnd}
            >
              <source
                src={aftermathIslandsFirstVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>

          {videoCompleted && !waitingForPhone ? (
            <div className="right-fold-top-single landing-col-6-top-single">
              <h1 className="header-2">
                Please Scan the QR code below using LiquidAvatar app
              </h1>
              <div className="credentials-align"></div>

              {props.QRCodeURL && !waitingForPhone ? (
                <div className="qr">
                  <p>
                    <QR value={props.QRCodeURL} size={512} renderAs="svg" />
                  </p>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          ) : videoCompleted && waitingForPhone ? (
            <div className="right-fold-top landing-col-6-top">
              <h1 className="header-2">Waiting for credential...</h1>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      )

      if (verificationStatus !== undefined) {
        if (!requestedAllCredentials) {
          props.sendRequest('CREDENTIALS', 'GET_ALL', {
            params: {
              sort: [['created_at', 'DESC']],
              pageSize: '10',
            },
          })

          setRequestedAllCredentials(true)
        }
        if (verificationStatus) {
          if (verifiedCredential && !aftermathFormDone) {
            try {
              setPhoneNumber(props.verifiedCredential.phone.raw)
              let rawPhoneNumber = parsePhoneNumber(
                props.verifiedCredential.phone.raw
              )
              setCountry(rawPhoneNumber.country)
            } catch (exception) {
              if (!missingAttributes) {
                setMissingAttributes(true)
              }
              console.log('Missing attributes')
            }

            setAftermathFormDone(true)
          }
        }

        result = missingAttributes ? (
          <div className="right-fold landing-col-6">
            <h1 className="header">Missing attributes</h1>
          </div>
        ) : (
          <div>
            <div className="right-fold landing-col-6">
              <video
                id="aftermathIslandsVideo"
                autoPlay="autoplay"
                muted
                onEnded={onVideoEnd}
              >
                <source
                  src={aftermathIslandsFirstVideo}
                  type="video/mp4"
                ></source>
              </video>
            </div>

            {videoCompleted ? (
              <div className="right-fold-top landing-col-6-top">
                <div className="row">
                  <div className="column">
                    <h1 className="header-2">
                      Please fill in the required fields
                    </h1>
                    <form className="form" id="myForm">
                      <label className="label" htmlFor="phone-number">
                        Phone Number:
                      </label>
                      <input
                        type="text"
                        id="phone-number"
                        name="phone-number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></input>
                      <label className="label" htmlFor="country">
                        Country:
                      </label>
                      <input
                        type="text"
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      ></input>
                      <label className="label" htmlFor="date-of-birth">
                        Date of birth (MM/DD/YYYY):
                      </label>
                      <DatePicker
                        id="date-of-birth"
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </form>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={clickAftermathSignupComplete}
                >
                  Signup
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )
      }
    } else if (isAftermathSignupComplete) {
      result = (
        <div className="right-fold landing-col-6">
          <video id="restrictedContentVideo" autoPlay="autoplay" muted>
            <source src={aftermathIslandsSecondVideo} type="video/mp4"></source>
          </video>
        </div>
      )
    }

    console.log('Verification Status!')
    console.log(verificationStatus)

    if (
      (verificationStatus !== undefined ||
        (credentialVerified === true && verificationResult.result == true)) &&
      isAftermathLogin
    ) {
      if (
        verificationStatus ||
        (credentialVerified === true && verificationResult.result == true)
      ) {
        if (
          verifiedCredential ||
          (credentialVerified === true && verificationResult.result == true)
        ) {
          result = (
            <div className="right-fold landing-col-6">
              <video id="aftermathIslandsVideo" autoPlay="autoplay" muted>
                <source
                  src={aftermathIslandsSecondVideo}
                  type="video/mp4"
                ></source>
              </video>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">{verifiedCredential}</h1>
          </div>
        )
      }
    }

    return result
  }

  const displayAgeValidation = () => {
    const { verifiedCredential, verificationStatus, credentialsLoaded } = props

    let result = (
      <div className="right-fold landing-col-6">
        {!waitingForQuestion &&
        !waitingForPassport &&
        !waitingForDriversLicense ? (
          <h1 className="header">
            Please scan the QR code below to prove your age
          </h1>
        ) : (
          <h1 className="header">Waiting for credential...</h1>
        )}
        <div className="credentials-align"></div>

        {props.QRCodeURL &&
        !waitingForQuestion &&
        !waitingForPassport &&
        !waitingForDriversLicense ? (
          <div className="qr">
            <p>
              <QR value={props.QRCodeURL} size={512} renderAs="svg" />
            </p>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential && !processComplete) {
          try {
            let age = Math.floor(
              (new Date().getTime() / 1000 -
                props.verifiedCredential.date_of_birth.raw) /
                (60 * 60 * 24 * 365)
            )

            if (age >= 18 && age <= 21) {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">
                    Success! You are between 18 and 21 years old!
                  </h1>
                </div>
              )
            } else if (age < 18) {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">Sorry! You are under 18!</h1>
                </div>
              )
            } else if (age > 21) {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">
                    Success! You are over 21 years old!
                  </h1>
                </div>
              )
            }
          } catch (exception) {
            if (!missingAttributes) {
              setMissingAttributes(true)
            }
            console.log('Missing attributes')
          }
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">{verifiedCredential}</h1>
          </div>
        )
      }
    }

    if (missingAttributes) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      )
    }

    return result
  }

  const displayRestrictedContent = () => {
    const { questionAnswer, verifiedCredential, verificationStatus } = props

    function onVideoEnd() {
      setVideoCompleted(true)
    }

    let result = (
      <div>
        <div className="right-fold landing-col-6">
          <video
            id="restrictedContentVideo"
            autoPlay="autoplay"
            muted
            onEnded={onVideoEnd}
          >
            <source src={restrictedContentFirstVideo} type="video/mp4"></source>
          </video>
        </div>

        {videoCompleted &&
        !waitingForQuestion &&
        !waitingForPassport &&
        !waitingForDriversLicense &&
        !processComplete ? (
          <div className="stack-top">
            <h1 className="header-top">
              Please Scan the QR code below using LiquidAvatar app to prove your
              age
            </h1>

            {props.QRCodeURL && !waitingForQuestion ? (
              <div className="qr-big">
                <p>
                  <QR value={props.QRCodeURL} size={256} renderAs="svg" />
                </p>
              </div>
            ) : (
              <p></p>
            )}
          </div>
        ) : videoCompleted && waitingForQuestion && !rejectedAge ? (
          <h1 className="header-top">Waiting for document type...</h1>
        ) : videoCompleted &&
          (waitingForPassport || waitingForDriversLicense) &&
          !rejectedAge ? (
          <h1 className="header-top">Waiting for credential...</h1>
        ) : rejectedAge ? (
          <h1 className="header-top">
            Sorry! You are not allowed to view this site's content!
          </h1>
        ) : (
          <p></p>
        )}
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential && !processComplete) {
          try {
            let age = Math.floor(
              (new Date().getTime() / 1000 -
                props.verifiedCredential.date_of_birth.raw) /
                (60 * 60 * 24 * 365)
            )

            if (age >= 18) {
              result = (
                <div className="right-fold landing-col-6">
                  <video id="restrictedContentVideo" autoPlay="autoplay" muted>
                    <source
                      src={restrictedContentSecondVideo}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              )
            } else {
              if (!rejectedAge) {
                setRejectedAge(true)
              }
            }
          } catch (exception) {
            if (!missingAttributes) {
              setMissingAttributes(true)
            }
            console.log('Missing attributes')
          }
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">{verifiedCredential}</h1>
          </div>
        )
      }
    }

    if (missingAttributes) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      )
    }

    return result
  }

  const displayRestaurantReservation = () => {
    const { verifiedCredential, verificationStatus } = props

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Welcome to our restaurant!</h1>
        <button className="btn" onClick={clickReserveTable}>
          Reserve a table
        </button>
        <div className="credentials-align"></div>
      </div>
    )

    if (covidVaccinationPage && !hasVaccination) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Do you have a phone credential?</h1>
          <button className="btn" onClick={clickYesVaccine}>
            Yes
          </button>
          <button className="btn" onClick={clickNoVaccine}>
            No
          </button>
          <div className="credentials-align"></div>
        </div>
      )
    } else if (!covidVaccinationPage && hasVaccination) {
      function onVideoEnd() {
        setVideoCompleted(true)
      }

      result = (
        <div>
          <div className="right-fold landing-col-6">
            <video
              id="restaurantReservationVideo"
              autoPlay="autoplay"
              muted
              onEnded={onVideoEnd}
            >
              <source
                src={restaurantReservationFirstVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>

          {videoCompleted && !waitingForPhone ? (
            <div className="landing-col-6-top restaurant-reservation">
              <h1 className="header-3">
                Please Scan the QR code below using LiquidAvatar app to prove
                phone credential
              </h1>

              <div className="credentials-align"></div>

              {invitationUrl && !waitingForPhone ? (
                <div>
                  <div className="qr">
                    <p>
                      <QR value={invitationUrl} size={256} renderAs="svg" />
                    </p>
                  </div>
                  <h1 className="header-3">
                    Or{' '}
                    <a
                      href={
                        'https://kabnnetwork.page.link/?efr=0&ibi=com.kabnnetwork.liquidavatar&apn=com.kabnnetwork.liquidavatar&link=' +
                        encodeURIComponent(
                          'https://liquidavatar.com/?c_i=' +
                            invitationUrl.split('c_i=')[1] +
                            '&redirect=' +
                            encodeURIComponent(
                              window.location.href.toString() +
                                '?verification_id=' +
                                verificationId +
                                '&use_case=' +
                                useCase
                            )
                        )
                      }
                    >
                      click this link to provide a credential
                    </a>
                  </h1>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          ) : videoCompleted && waitingForPhone ? (
            <div className="right-fold-top landing-col-6-top restaurant-reservation">
              <h1 className="header-2">Waiting for credential...</h1>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      )
    }

    if (rejectVaccination) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">
            Sorry! You cannot reserve a table without providing a phone
            credential!
          </h1>
        </div>
      )
    }

    if (credentialVerified) {
      if (verificationResult.result === true) {
        result = (
          <div className="right-fold landing-col-6">
            <video id="restaurantReservationVideo" autoPlay="autoplay" muted>
              <source
                src={restaurantReservationSecondVideo}
                type="video/mp4"
              ></source>
            </video>
          </div>
        )
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    if (missingAttributes) {
      result = (
        <div className="right-fold landing-col-6">
          <h1 className="header">Missing attributes</h1>
        </div>
      )
    }

    return result
  }

  const displayLoaderCrypto = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPassport) {
      return ''
    }

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Waiting for credential...</h1>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          const container = document.querySelector('.home')
          const options = {
            rocketsPoint: 50,
            hue: { min: 100, max: 360 },
            delay: { min: 15, max: 30 },
            speed: 2,
            acceleration: 1.5,
            friction: 0.95,
            gravity: 1.5,
            particles: 450,
            trace: 3,
            explosion: 5,
            autoresize: true,
            brightness: {
              min: 50,
              max: 80,
              decay: { min: 0.015, max: 0.03 },
            },
          }

          const fireworks = new Fireworks(container, options)
          fireworks.start()

          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">KYC credential provided!</h1>
              <h1 className="header">Registration complete!</h1>
            </div>
          )
        } else {
          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">No credential data was passed.</h1>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    return result
  }

  const displayLoaderPassport = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForPassport) {
      return ''
    }

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Waiting for credential...</h1>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          try {
            let age = Math.floor(
              (new Date().getTime() / 1000 -
                props.verifiedCredential.date_of_birth.raw) /
                (60 * 60 * 24 * 365)
            )

            if (age >= 18) {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">Passport credential received</h1>
                  <h1 className="header">
                    Credential verified! You are over 18!
                  </h1>
                  <h1 className="header">You can proceed to the site</h1>
                </div>
              )

              const container = document.querySelector('.home')
              const options = {
                rocketsPoint: 50,
                hue: { min: 100, max: 360 },
                delay: { min: 15, max: 30 },
                speed: 2,
                acceleration: 1.5,
                friction: 0.95,
                gravity: 1.5,
                particles: 450,
                trace: 3,
                explosion: 5,
                autoresize: true,
                brightness: {
                  min: 50,
                  max: 80,
                  decay: { min: 0.015, max: 0.03 },
                },
              }

              const fireworks = new Fireworks(container, options)
              fireworks.start()
            } else {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">Passport credential received</h1>
                  <h1 className="header">Sorry! You are not over 18!</h1>
                  <h1 className="header">
                    You are not allowed to view this site's content
                  </h1>
                </div>
              )
            }
          } catch (exception) {
            if (!missingAttributes) {
              setMissingAttributes(true)
            }
            console.log('Missing attributes')
          }
        } else {
          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">No credential data was passed.</h1>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    return result
  }

  const displayLoaderDriversLicense = () => {
    const { verifiedCredential, verificationStatus } = props

    if (!waitingForDriversLicense) {
      return ''
    }

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Waiting for credential...</h1>
      </div>
    )

    if (verificationStatus !== undefined) {
      if (verificationStatus) {
        if (verifiedCredential) {
          try {
            let age = Math.floor(
              (new Date().getTime() / 1000 -
                props.verifiedCredential.date_of_birth.raw) /
                (60 * 60 * 24 * 365)
            )

            if (age >= 18) {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">Passport credential received</h1>
                  <h1 className="header">
                    Credential verified! You are over 18!
                  </h1>
                  <h1 className="header">You can proceed to the site</h1>
                </div>
              )

              const container = document.querySelector('.home')
              const options = {
                rocketsPoint: 50,
                hue: { min: 100, max: 360 },
                delay: { min: 15, max: 30 },
                speed: 2,
                acceleration: 1.5,
                friction: 0.95,
                gravity: 1.5,
                particles: 450,
                trace: 3,
                explosion: 5,
                autoresize: true,
                brightness: {
                  min: 50,
                  max: 80,
                  decay: { min: 0.015, max: 0.03 },
                },
              }

              const fireworks = new Fireworks(container, options)
              fireworks.start()
            } else {
              result = (
                <div className="right-fold landing-col-6">
                  <h1 className="header">Passport credential received</h1>
                  <h1 className="header">Sorry! You are not over 18!</h1>
                  <h1 className="header">
                    You are not allowed to view this site's content
                  </h1>
                </div>
              )
            }
          } catch (exception) {
            if (!missingAttributes) {
              setMissingAttributes(true)
            }
            console.log('Missing attributes')
          }
        } else {
          result = (
            <div className="right-fold landing-col-6">
              <h1 className="header">No credential data was passed.</h1>
            </div>
          )
        }
      } else {
        result = (
          <div className="right-fold landing-col-6">
            <h1 className="header">
              Credential verification failed! {verifiedCredential}
            </h1>
          </div>
        )
      }
    }

    return result
  }

  const displayLoaderQuestion = () => {
    const { questionAnswer } = props

    if (!waitingForQuestion) {
      return ''
    }

    let result = (
      <div className="right-fold landing-col-6">
        <h1 className="header">Waiting for document type...</h1>
      </div>
    )

    if (questionAnswer !== undefined) {
      if (questionAnswer) {
        if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('passport') &&
          waitingForQuestion
        ) {
          requestPassport()
        } else if (
          props.questionAnswer.response
            .toString()
            .toLowerCase()
            .includes('drivers') &&
          waitingForQuestion
        ) {
          requestDriversLicense()
        }
      }
    }
  }

  const displayCredentialData = () => {
    let phoneResult = displayPhone()
    let emailResult = displayEmail()
    let driversLicenseResult = displayDriversLicense()
    let passportResult = displayPassport()
    let vaccinationResult = displayVaccination()

    return (
      <div className="right-fold landing-col-6">
        {props.QRCodeURL ? (
          <div className="qr">
            <p>
              <QR value={props.QRCodeURL} size={256} renderAs="svg" />
            </p>
          </div>
        ) : (
          <p>
            <span>Loading...</span>
          </p>
        )}

        <h1 className="header">Verify Your Credentials</h1>
        <div className="credentials-align">
          <p>Select your credential type</p>

          <button onClick={requestPhone}>Phone</button>

          <button onClick={requestEmail}>Email</button>

          <button onClick={requestDriversLicense}>Drivers License</button>

          <button onClick={requestPassport}>Passport</button>

          <button onClick={requestVaccination}>Vaccination</button>

          {phoneResult}
          {emailResult}
          {driversLicenseResult}
          {passportResult}
          {vaccinationResult}
        </div>
      </div>
    )
  }

  let time = new Date().getTime()
  const setActivityTime = (e) => {
    time = new Date().getTime()
  }
  document.body.addEventListener('mousemove', setActivityTime)
  document.body.addEventListener('keypress', setActivityTime)

  const refresh = () => {
    if (new Date().getTime() - time >= 420000) {
      window.location.reload(true)
    } else {
      setTimeout(refresh, 300000)
    }
  }

  setTimeout(refresh, 300000)

  return !verifier ? (
    <>
      <div className="landing-container-fluid">
        <div className="landing-row">
          <div id="home" className="home landing-col s12">
            <div className="landing-col upper-fold">
              <button className="btn-home" onClick={reloadPage}>
                <img id="logo" src={liquidLogo} className="img-fluid" alt="" />
              </button>
              {isHomeScreen ? (
                <button
                  className="btn-age-validation"
                  onClick={clickAgeValidation}
                >
                  Age Validation
                </button>
              ) : (
                <div></div>
              )}
              {isHomeScreen ? (
                <button className="btn-verifier" onClick={clickVerifier}>
                  Verifier
                </button>
              ) : (
                <div></div>
              )}
              <div></div>

              {!aftermathLogin &&
              !restrictedContent &&
              !restaurantReservation &&
              !cryptoLogin &&
              !ageValidation ? (
                displayWelcomeScreen()
              ) : (
                <div className="landing-container">
                  <div className="landing-row">
                    {aftermathLogin
                      ? displayAftermathLogin()
                      : restrictedContent
                      ? displayRestrictedContent()
                      : restaurantReservation
                      ? displayRestaurantReservation()
                      : cryptoLogin
                      ? displayCryptoLogin()
                      : ageValidation
                      ? displayAgeValidation()
                      : displayWelcomeScreen()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <OldVerifierRoot
      schemas={props.schemas}
      verifiedCredential={props.verifiedCredential}
      verificationStatus={props.verificationStatus}
      setVerifiedCredential={props.setVerifiedCredential}
      setVerificationStatus={props.setVerificationStatus}
      sendRequest={props.sendRequest}
      contacts={props.contacts}
      QRCodeURL={props.QRCodeURL}
    />
  )
}

export default Root
